import { postBodyRequest, getRequest } from '@/utils/axios'

export function getPageList (params) {
  return postBodyRequest('/market/crm/contract/page', params)
}

export function getDetail (contactId, params) {
  return getRequest(`/market/crm/contract/${contactId}`, params)
}

export function examine (params) {
  return postBodyRequest('/market/crm/contract/examine', params)
}

export function getStatus (params) {
  return postBodyRequest('/market/crm/contract/getCountContractStatus', params)
}

export function deleteAct (contactId) {
  return postBodyRequest(`/market/crm/contract/${contactId}/delete`)
}
